import React, {useContext} from "react";
import "./Quotes.css";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import {educationInfo} from "../../portfolio";
import EducationCard from "../educationCard/EducationCard";

export default function Quotes(props) {
  const {isDark} = useContext(StyleContext);
  const {cardData} = props;

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="quote-section" id="quote">
        <h1 className="quote-heading">Quote</h1>
        <div className="quote-card-container container-body">
          <div className="container">
            <div id="quote-box">
              <p className={isDark ? "quote quote-dark quote-font" : "quote quote-light quote-font"}>When you want to succeed as bad as you want to breathe, then you'll be successful.</p>
              <p className={isDark ? "source quote-dark" : "source quote-light"}>Eric Thomas</p>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
