import React, {createRef, useContext} from "react";
import {Fade, Slide} from "react-reveal";
import "./earth.scss";
import StyleContext from "../../contexts/StyleContext";

export default function Earth({school}) {
  const imgRef = createRef();

  const {isDark} = useContext(StyleContext);
  return (
    <div className="cradle-container">
      <Fade left duration={1000}>
        <article className={isDark ? "card card--earth" : "card card--earth-light"}>
            <div className="planet__atmosphere">
              <div className="planet__surface"></div>
            </div>
        </article>
      </Fade>
    </div>
  );
}
