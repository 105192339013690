import React, {useState, useContext} from "react";
import StyleContext from "../../contexts/StyleContext";
import "./ToggleSwitch.scss";

const ToggleSwitch = () => {
  const {isDark} = useContext(StyleContext);
  const [isChecked, setChecked] = useState(isDark);
  const styleContext = useContext(StyleContext);

  return (
      <label className="switch">
        <input type="checkbox" checked={isDark}
               onChange={() => {
                 styleContext.changeTheme();
                 setChecked(isChecked);
               }} />
          <div>
            <span></span>
          </div>
      </label>
  );
};
export default ToggleSwitch;
