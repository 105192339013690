import React,{useContext} from "react";
import "./LoadingScreen.scss";
import StyleContext from "../../contexts/StyleContext";
import {greeting} from "../../portfolio";

export default function LoadingScreen() {
  const {isDark} = useContext(StyleContext);

  return (
    <div className={isDark ? "dark-screen" : "light-screen"} >
      <div className="dots-container">
        <div className="dots"></div>
        <div className="dots"></div>
        <div className="dots"></div>
        <div className="dots"></div>
        <div className="dots"></div>
      </div>
    </div>
  );
}
