import React, {useContext} from "react";
import "./ProjectCard.css";
// import {bigProjects} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

export default function ProjectCard(props) {
  const {isDark} = useContext(StyleContext);
  const {cardData} = props;

  // if (!bigProjects.display) {
  //   return null;
  // }
  return (
    <Fade bottom duration={1000} distance="20px">
      <figure className={isDark ? "snip1477 dark-mode" : "snip1477 light-mode"}>
        <img src={cardData.image} alt="sample38" className="project-image"/>
        <div className="title">
          <div>
            <h2>{cardData.projectName}</h2>
            <h4 className="year">{cardData.footerLink[0].name}</h4>
            <a href={cardData.footerLink[0].url}></a>
          </div>
        </div>
        <figcaption>
          <p>{cardData.projectDesc}</p>
        </figcaption>

      </figure>
    </Fade>
  );
}
